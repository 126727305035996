<template>
  <container
    style="
      justify-content: center !important;
      align-items: center !important;
      height: 100% !important;
      display: flex !important;
      flex-direction: column !important;
    "
  >
    <h3>Oops! We couldn't find what you were looking for...</h3>

    <btn-group>
      <btn size="lg" severity="tertiary" @click="() => $router.go(-1)">
        <template #icon>
          <font-awesome-icon icon="arrow-left" />
        </template>
        Go back
      </btn>
      <btn size="lg" severity="tertiary" @click="() => $router.push('/home')">
        <template #icon>
          <font-awesome-icon icon="arrow-left" />
        </template>
        Go home
      </btn>
    </btn-group>
  </container>
</template>

<script>
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  name: 'NotFoundPage',
  data() {
    return {}
  },
  methods: {}
}
</script>
